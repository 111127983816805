import React from "react"
import { getStrapiMedia } from "../../utils/media"

const ContactUsForm = ({data, locale, defaultLocale }) => {
  return (
    <>
			<div class="contact-entry container">
				<div class="row">
					<div class="col-lg-9">
						<div class="post-content">
							<p>Fusce ac lorem quis nibh hendrerit porttitor quis ac eros. Nam consectetur lacinia augue, quis consequat lectus consequat consectetur. Nulla at vulputate arcu. Nam sit amet diam et erat rhoncus dapibus. Etiam ornare tortor diam, sed maximus nibh consequat vitae. Vestibulum eu laoreet libero odio eget elit ornare efficitur nec nec orci.</p>
						</div>
						<form id="rate-form" class="form">
							<p class="form-message error">Message should not be empty</p>
							<p class="form-message saccess">Message sent saccessfuly</p>
							<div class="row-input">
								<div class="item-wr">
									<input type="text" name="name" placeholder="Your Name" class="name" />
								</div>
								<div class="item-wr">
									<input type="email" name="email" placeholder="Your Email" class="email" />
								</div>
							</div>
							<textarea name="review-message" placeholder="Message"></textarea>
							<input type="submit" value="Send message" class="btn-classic" />
						</form>
					</div>
				</div>
			</div>
    </>
  )
}

export default ContactUsForm
