import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import SeoComp from "@/components/seo"

const DynamicPage = ({ data, pageContext }) => {
  const { sections, Seo, locale, localizations } = data.strapiPage
  const defaultLocale = data.site.siteMetadata.languages.defaultLocale
  const global = data.strapiGlobal
  const tables = {
    allStrapiBody: data.allStrapiBody,
    allStrapiEye: data.allStrapiEye,
    allStrapiHair: data.allStrapiHair,
    allStrapiActor: data.allStrapiActor,
    allStrapiBlog: data.allStrapiBlog,
  }

  const [searchResult, setSearchResult] = useState([]);

  const searchRef = useRef();

  const populateSearchResult = (result) => {
    setSearchResult(result);
    if(searchRef && searchRef.current) {
      searchRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    
  }

  return (
    <>
      <SeoComp seo={Seo} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }} tables={tables} 
        populateSearchResult={populateSearchResult} searchResult={searchResult} searchRef={searchRef}>
        <Sections sections={sections} locale={locale} defaultLocale={defaultLocale} tables={tables} 
          populateSearchResult={populateSearchResult} searchResult={searchResult} searchRef={searchRef} />
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    logo {
      localFile {
        publicURL
      }
    }
    copyright
    footbarLinks {
      id
      title
      url
      newTab
      icon 
    }
    id
    seo {
      metaTitle
      metaDescription
      media {
        localFile {
          publicURL
        }
      }
      mediaAlt
      keywords
      preventIndex
    }
    navbar {
      url
      title
      newTab
      id
    }
  }

  query DynamicPageQuery($id: String!, $locale: String!) {
    site {
      siteMetadata {
        languages {
          defaultLocale
        }
      }
    }
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiPage(id: { eq: $id }) {
      name
      slug
      Seo {
        metaTitle
        metaDescription
        keywords
        mediaAlt
        media {
          localFile {
            publicURL
          }
        }
        preventIndex
      }
      locale
      localizations {
        id
        locale
      }
      sections 
    }
    allStrapiBody(filter: {locale: {eq: $locale}}) {
      nodes {
        id
        name
        strapiId
      }
    }
    allStrapiEye(filter: {locale: {eq: $locale}}) {
      nodes {
        id
        name
        strapiId
      }
    }
    allStrapiHair(filter: {locale: {eq: $locale}}) {
      nodes {
        id
        name
        strapiId
      }
    }
    allStrapiActor(filter: {locale: {eq: $locale}}) {
      nodes {
        id
        strapiId
        status
        name
        lastname
        stagename
        domain
        birth_at
        gender
        weight
        height
        headshot {
          alternativeText,
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 350
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        eye {
          id
          name
        }
        hair {
          id
          name
        }
        body {
          name
          id
        }
        Social {
          facebook
          instagram
          id
        }
        Profile {
          imdb
          kinosan
          id
        }
        bio
        languages {
          name
          id
        }
        skills {
          id
          title
          type
        }
        Trainings {
          degree
          id
          school
          year
        }
        Films {
          id
          type
        }
        Awards {
          id
          title
          year
        }
      }
    }
    allStrapiBlog(filter: {locale: {eq: $locale}}) {
      nodes {
        id
        strapiId
        title
        description
        slug
        locale
        published_at
        blog_category {
          id
          name
          slug
        }
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 350
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
        Seo {
          id
          keywords
          mediaAlt
          metaDescription
          metaTitle
          preventIndex
          media {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
