import React from "react"
import { getStrapiMedia } from "../../utils/media"
import { Link } from "gatsby"
import { localizePath } from "@/utils/localize"

const BlogList = ({data, locale, tables, defaultLocale }) => {

  const labelData = (locale !== 'mn') ? 
    {
      viewPost: "View Post",
      blogGrid: "Articles",
    } : {
      viewPost: "Нийтлэл харах",
      blogGrid: "Мэдээ"
    }

  return (
    <>
      <div className="container">
        <div className="wrapper-breadcrumbs">
          <div id="breadcrumbs">
            <span className="breadcrumb-last">{labelData.blogGrid}</span>
          </div>
        </div>
      </div>

      <div className="container blog-grid small last-posts">
			  <div className="row">
          {tables.allStrapiBlog.nodes.map(blog => (
            <article className="col-md-6 col-lg-4 last-post-wr" key={blog.id}>
              <Link to={localizePath({locale: locale, defaultLocale: defaultLocale, isPreview: false, slug: "blog/"+blog.slug })} className="post-item no-decoration">
                <figure className="post-image" style={{ backgroundImage: `url(${getStrapiMedia(blog.image.url)}` }}>
                  <div className="info">
                    <p className="post-btn">{labelData.viewPost}</p>
                    <p className="post-publish">
                      <span className="publish-date">{blog.published_at}</span>
                    </p>
                  </div>
                </figure>
                <footer className="post-footer">
                  <p className="post-category">{blog.blog_category && blog.blog_category.name}</p>
                  <h2 className="post-title">{blog.title}</h2>
                </footer>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </>
  )
}

export default BlogList
