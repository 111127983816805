import React, {useEffect, useState} from "react"
import { getStrapiMedia } from "../../utils/media"
import axios from "axios"
import { Link } from "gatsby"
import { getStrapiURL } from "../../utils/api"
import { localizePath } from "@/utils/localize"
import ReactMarkdown from "react-markdown"

const CastingCallList = ({data, locale, defaultLocale }) => {

  const [calllist, setCalllist] = useState([]);

  useEffect(() => {
    axios.get(getStrapiURL(`/casting-calls`))
      .then(res => {
        const resp = res.data;

        const callList = resp.filter(ccall => ccall.locale == locale);
        setCalllist(callList)
      })

  }, [locale, setCalllist])

  const labelData = (locale !== 'mn') ? 
    {
      listTitle: "Casting calls",
    } : {
      listTitle: "Кастинг"
    }

  const onPress = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <div className="container">
        <div className="wrapper-breadcrumbs">
          <div id="breadcrumbs">
            <span className="breadcrumb-last">{labelData.listTitle}</span>
          </div>
        </div>
      </div>

      <div className="container">
			  <div className="row">
          <div className="all-review">
            {calllist.map(ccall => (
							<article className="blog-comment" key={ccall.id}>
								<div className="author-content">
									<header className="comment-header">
										<h3 className="author-name">{ccall.title}</h3>
										<p className="publish">{ccall.date_at}</p>
									</header>
									<div className="block-content">
                    <ReactMarkdown transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.GATSBY_STRAPI_URL}${uri}`}>{ccall.description}</ReactMarkdown>
									</div>
								</div>
							</article>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default CastingCallList
