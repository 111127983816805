import React from "react"
import ReactMarkdown from "react-markdown"
import Image from "../image"

import { getStrapiMedia } from "../../utils/media"

const ThreeColumnArticle = ({data, locale, defaultLocale }) => {
  return (
    <>
      <div className="about-us-entry">
        <section className="services">
          <div className="container">
            <h2 className="section-title lines">{data.title}</h2>
            <div className="row">
              <div className="col-md-4 services-item-wr">
                <p className="icon-wr">
                  <Image media={data.Icon1} style={{ maxWidth: 150 }} />
                </p>
                <p className="description">
                  <ReactMarkdown>{data.col1}</ReactMarkdown>
                </p>
              </div>
              <div className="col-md-4 services-item-wr">
                <p className="icon-wr">
                  <Image media={data.Icon2} style={{ maxWidth: 150 }} />
                </p>
                <p className="description">
                  <ReactMarkdown>{data.col2}</ReactMarkdown>
                </p>
              </div>
              <div className="col-md-4 services-item-wr">
                <p className="icon-wr">
                  <Image media={data.Icon3} style={{ maxWidth: 150 }} />
                </p>
                <p className="description">
                  <ReactMarkdown>{data.col3}</ReactMarkdown>
                </p>
              </div>
            </div>
          </div>
        </section>
	    </div>
    </>
  )
}

export default ThreeColumnArticle
