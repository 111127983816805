import React, { useState } from "react"
import { getStrapiMedia } from "../../utils/media"

/* eslint eqeqeq: 0 */

const SearchBar = ({ data, locale, tables, populateSearchResult }) => {
    const genderData = (locale != 'mn') ? 
        {
            title: "Gender",
            male: "Male",
            female: "Female"
        } : {
            title: "Хүйс",
            male: "Эрэгтэй",
            female: "Эмэгтэй",
        };
    
    const ageData = (locale != 'mn') ?
        {
            title: "Age"
        } : {
            title: "Нас"
        };
    
    const heightData = (locale != 'mn') ?
        {
            title: "Height"
        } : {
            title: "Өндөр"
        };
    
    const weightData = (locale != 'mn') ?
        {
            title: "Weight"
        } : {
            title: "Жин"
        };

    const bodyData = (locale != 'mn') ?
        {
            title: "Body"
        } : {
            title: "Бие"
        };

    const hairData = (locale != 'mn') ?
        {
            title: "Hair"
        } : {
            title: "Үс"
        };
    
    const searchLabel = (locale != 'mn') ? 'Search' : 'Хайх';

    const getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m == 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        return age;
    }

    const [gender, setGender] = useState("0");
    const [age, setAge] = useState("0");
    const [height, setHeight] = useState("0");
    const [weight, setWeight] = useState("0");
    const [body, setBody] = useState("0");
    const [hair, setHair] = useState("0");

    const genderFilter = (actor) => {
        if(gender == "0") {
            return true;
        }
        return actor.gender == gender;
    }
    const ageFilter = (actor) => {
        if(age == "0") {
            return true;
        }
        const actorAge = getAge(actor.birth_at);
        
        if (age == "10" && actorAge > 0 && actorAge < 20) {
            return true;
        }
        else if (age == "20" && actorAge >= 20 && actorAge < 30) {
            return true;
        }
        else if (age == "30" && actorAge >= 30 && actorAge < 40) {
            return true;
        }
        else if (age == "40" && actorAge >= 40 && actorAge < 50) {
            return true;
        }
        else if (age == "50" && actorAge >= 50 && actorAge < 60) {
            return true;
        }
        else if (age == "60" && actorAge >= 60) {
            return true;
        }
        
        return false;
    }
    const heightFilter = (actor) => {
        if(height == "0") {
            return true;
        }

        if(height == "130" && actor.height < 140) {
            return true;
        }
        else if(height == "140" && actor.height >= 140 && actor.height < 150) {
            return true;
        }
        else if(height == "150" && actor.height >= 150 && actor.height < 160) {
            return true;
        }
        else if(height == "160" && actor.height >= 160 && actor.height < 170) {
            return true;
        }
        else if(height == "170" && actor.height >= 170 && actor.height < 180) {
            return true;
        }
        else if(height == "180" && actor.height >= 180) {
            return true;
        }
        
        return false;
    }

    const weightFilter = (actor) => {
        if(weight == "0") {
            return true;
        }

        if(weight == "30" && actor.weight < 40) {
            return true;
        }
        else if(weight == "40" && actor.weight >= 40 && actor.weight < 50) {
            return true;
        }
        else if(weight == "50" && actor.weight >= 50 && actor.weight < 60) {
            return true;
        }
        else if(weight == "60" && actor.weight >= 60 && actor.weight < 70) {
            return true;
        }
        else if(weight == "70" && actor.weight >= 70 && actor.weight < 80) {
            return true;
        }
        else if(weight == "80" && actor.weight >= 80 && actor.weight < 90) {
            return true;
        }
        else if(weight == "90" && actor.weight >= 90) {
            return true;
        }
        
        return false;
    }

    const bodyFilter = (actor) => {
        if(body == "0") {
            return true;
        }
        return actor.body.id == body
    }

    const hairFilter = (actor) => {
        if(hair == "0") {
            return true;
        }
        return actor.hair.id == hair
    }

    const onSearch = (event) => {

        const found = tables.allStrapiActor.nodes.filter(genderFilter).filter(ageFilter).filter(heightFilter).filter(weightFilter).filter(bodyFilter).filter(hairFilter)

        populateSearchResult(found);
        event.preventDefault();
    };

    const handleSelectChange = (event) => {
        switch(event.target.id) {
            case 'genderSelect':
                setGender(event.target.value);
                break;
            case 'ageSelect':
                setAge(event.target.value);
                break;
            case 'heightSelect':
                setHeight(event.target.value);
                break;
            case 'weightSelect':
                setWeight(event.target.value);
                break;
            case 'bodySelect':
                setBody(event.target.value);
                break;
            case 'hairSelect':
                setHair(event.target.value);
                break;
            default:
                break;
        }
    };

    return (
        <section className="hero over-header" style={{ backgroundImage: `url(${getStrapiMedia(data.image.url)}` }}>
            <div className="container">
                <div className="over-header-inner">
                    <h1 className="title">{data.Title}</h1>
                    <form className="searh-model" onSubmit={onSearch}>
                        <div className="form-floating select-item">
                            <select className="form-select form-select-lg" 
                                id="genderSelect" 
                                aria-label="Floating label select example"
                                onChange={handleSelectChange}
                                value={gender}>
                                <option value="0">---</option>
                                <option value="male">{genderData.male}</option>
                                <option value="female">{genderData.female}</option>
                            </select>
                            <label htmlFor="genderSelect">{genderData.title}</label>
                        </div>
                        <div className="form-floating select-item">
                            <select className="form-select form-select-lg" 
                                id="ageSelect" 
                                aria-label="Floating label select example"
                                onChange={handleSelectChange}
                                value={age}>
                                <option value="0">---</option>
                                <option value="10">10 - 20</option>
                                <option value="20">20 - 30</option>
                                <option value="30">30 - 40</option>
                                <option value="40">40 - 50</option>
                                <option value="50">50 - 60</option>
                                <option value="60">60 + </option>
                            </select>
                            <label htmlFor="ageSelect">{ageData.title}</label>
                        </div>
                        <div className="form-floating select-item">
                            <select className="form-select form-select-lg" 
                                id="heightSelect" 
                                aria-label="Floating label select example"
                                onChange={handleSelectChange}
                                value={height}>
                                <option value="0">---</option>
                                <option value="130">140 - </option>
                                <option value="140">140 - 150</option>
                                <option value="150">150 - 160</option>
                                <option value="160">160 - 170</option>
                                <option value="170">170 - 180</option>
                                <option value="180">180 + </option>
                            </select>
                            <label htmlFor="heightSelect">{heightData.title}</label>
                        </div>
                        <div className="form-floating select-item">
                            <select className="form-select form-select-lg" 
                                id="weightSelect" 
                                aria-label="Floating label select example"
                                onChange={handleSelectChange}
                                value={weight}>
                                <option value="0">---</option>
                                <option value="30">40 - </option>
                                <option value="40">40 - 50</option>
                                <option value="50">50 - 60</option>
                                <option value="60">60 - 70</option>
                                <option value="70">70 - 80</option>
                                <option value="80">80 - 90</option>
                                <option value="90">90 +</option>
                            </select>
                            <label htmlFor="weightSelect">{weightData.title}</label>
                        </div>
                        <div className="form-floating select-item">
                            <select className="form-select form-select-lg" 
                                id="bodySelect" 
                                aria-label="Floating label select example"
                                onChange={handleSelectChange}
                                value={body}>
                                <option value="0">---</option>
                                {tables.allStrapiBody.nodes.map(body => (
                                    <option key={body.id} value={body.strapiId}>{body.name}</option>
                                ))}
                            </select>
                            <label htmlFor="bodySelect">{bodyData.title}</label>
                        </div>
                        <div className="form-floating select-item">
                            <select className="form-select form-select-lg" 
                                id="hairSelect" 
                                aria-label="Floating label select example"
                                onChange={handleSelectChange}
                                value={hair}>
                                <option value="0">---</option>
                                {tables.allStrapiHair.nodes.map(hair => (
                                    <option key={hair.id} value={hair.strapiId}>{hair.name}</option>
                                ))}
                            </select>
                            <label htmlFor="hairSelect">{hairData.title}</label>
                        </div>
                        <input type="submit" value={searchLabel} className="submit" />
                    </form>
                </div>
            </div>
        </section>
    )
}

export default SearchBar
