import React, { useState } from "react"
import Image from "../image"
import { Link } from "gatsby"
import { localizePath } from "@/utils/localize"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ResultComp = ({data, locale, defaultLocale, searchResult}) => {
    const labelData = (locale !== 'mn') ? 
    {
        height: "Height",
        weight: "Weight",
        age: "Age",
        body: "Body",
        hair: "Hair",
        eyes: "Eyes",
    } : {
        height: "Өндөр",
        weight: "Жин",
        age: "Нас",
        body: "Бие",
        hair: "Үс",
        eyes: "Нүд",
    };

    const getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        return age;
    }

    return (
        <section className="container popular-models model-row-wrap">
            <h2 className="section-title lines">{data.resultTitle}</h2>
            {searchResult.map(actor => 
                (<article className="model-row" key={actor.id}>
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-xl-2 offset-xl-0">
                            <Link className="img-wr" to={localizePath({locale: locale, defaultLocale: defaultLocale, isPreview: false, slug: actor.domain })}>
                                {actor.headshot && (<Image media={actor.headshot} />)}
                            </Link>
                        </div>
                        <div className="col-xl-6">
                            <div className="row-model-info">
                                <h3 className="title">
                                    <Link className="img-wr" to={localizePath({locale: locale, defaultLocale: defaultLocale, isPreview: false, slug: actor.domain })}>
                                        {locale == 'mn' && (
                                            <>
                                                {actor.lastname} {actor.name}
                                            </>
                                        )}
                                        {locale == 'en' && (
                                            <>
                                                {actor.name} {actor.lastname} 
                                            </>
                                        )}
                                    </Link>
                                </h3>
                                <div className="model-attr">
                                    <p className="attr-item">
                                        <span className="attr-name">{labelData.height}:</span>
                                        <span className="attr-value">{actor.height}</span>
                                    </p>
                                    <p className="attr-item">
                                        <span className="attr-name">{labelData.weight}:</span>
                                        <span className="attr-value">{actor.weight}</span>
                                    </p>
                                    <p className="attr-item">
                                        <span className="attr-name">{labelData.age}:</span>
                                        <span className="attr-value">{getAge(actor.birth_at)}</span>
                                    </p>
                                    <p className="attr-item">
                                        <span className="attr-name">{labelData.body}:</span>
                                        <span className="attr-value">{actor.body ? actor.body.name: ''}</span>
                                    </p>
                                    <p className="attr-item">
                                        <span className="attr-name">{labelData.hair}:</span>
                                        <span className="attr-value">{actor.hair ? actor.hair.name: ''}</span>
                                    </p>
                                    <p className="attr-item">
                                        <span className="attr-name">{labelData.eyes}:</span>
                                        <span className="attr-value">{actor.eye ? actor.eye.name: ''}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <p className="soc-icons-wr">
                                {actor.Social && actor.Social.facebook && (
																	<a href={actor.Social.facebook} target="_blank" className="soc-icon"><i className="fa fa-facebook-f"></i></a>
																)}
																{actor.Social && actor.Social.instagram && (
																	<a href={actor.Social.instagram} target="_blank" className="soc-icon"><i className="fa fa-instagram"></i></a>
																)}
																{actor.Social && actor.Social.web && (
																	<a href={actor.Social.web} target="_blank" className="soc-icon"><i className="fa fa-globe"></i></a>
																)}
																{actor.Profile && actor.Profile.youtube && (
																	<a href={actor.Profile.youtube} target="_blank" className="soc-icon"><i className="fa fa-youtube"></i></a>
																)}
																{actor.Profile && actor.Profile.kinosan && (
																	<a href={actor.Profile.kinosan} target="_blank" className="soc-icon"><i className="fa fa-film"></i></a>
																)}
																{actor.Profile && actor.Profile.imdb && (
																	<a href={actor.Profile.imdb} target="_blank" className="soc-icon"><i className="fa fa-imdb"></i></a>
																)}
                            </p>
                        </div>
                    </div>
                </article>
                )
            )}
        </section>
    );
}

const CategoryComp = ({data, locale, tables, populateSearchResult}) => {
    const labelData = (locale !== 'mn') ? 
    {
        actors: "Actors",
        actresses: "Actresses",
        trends: "Trends",
        kids: "Kids",
    } : {
        actors: "Эрэгтэй",
        actresses: "Эмэгтэй",
        trends: "Трэнд",
        kids: "Хүүхэд",
    };

    const menList = tables.allStrapiActor.nodes.filter(actor => actor.gender == 'male' && actor.headshot);
    const womenList = tables.allStrapiActor.nodes.filter(actor => actor.gender == 'female' && actor.headshot);
    const trendList = tables.allStrapiActor.nodes.filter(actor => actor.status == 'trend' && actor.headshot);
    const kidList = tables.allStrapiActor.nodes.filter(actor => actor.status == 'kid' && actor.headshot);

    const catData = {
        actors: {
            length: menList.length,
            selected: menList.length > 0 ? menList[Math.floor(Math.random() * menList.length)] : null,
        },
        actresses: {
            length: womenList.length,
            selected: womenList.length > 0 ? womenList[Math.floor(Math.random() * womenList.length)] : null,
        },
        trends: {
            length: trendList.length,
            selected: trendList.length > 0 ? trendList[Math.floor(Math.random() * trendList.length)] : null,
        },
        kids: {
            length: kidList.length,
            selected: kidList.length > 0 ? kidList[Math.floor(Math.random() * kidList.length)] : null,
        },
    }

    const handleCatClick = (e) => {
        e.preventDefault();
        if(e.target.id == 'actors') {
            populateSearchResult(menList);
        }
        else if(e.target.id == 'actresses') {
            populateSearchResult(womenList);
        }
        else if(e.target.id == 'trends') {
            populateSearchResult(trendList);
        }
        else if(e.target.id == 'kids') {
            populateSearchResult(kidList);
        }
    }

    return (
        <section className="container categories bigger">
            <h2 className="section-title lines">{data.title}</h2>
			<div className="row categories-wr">
                {data.CategoryList && data.CategoryList.map((catType) => (
                    <div className="col-sm-6 col-lg-3 categories-item-wr" key={catType.id}>
                        <a href="#" className="category-models-item" 
                            id={catType.categoryType}
                            onClick={handleCatClick}
                            style={{ backgroundImage: `url(${catData[catType.categoryType].selected ? catData[catType.categoryType].selected.headshot.localFile.publicURL : ''}` }}>
                            <footer className="categories-item-footer">
                                <p className="category">{labelData[catType.categoryType]}</p>
                                <p className="models-count">{catData[catType.categoryType].length}</p>
                            </footer>
                        </a>
                    </div>
                ))}
			</div>
		</section>
    );
}


const CategoryResult = ({ data, locale, defaultLocale, tables, populateSearchResult, searchResult, searchRef }) => {

    return (
        <>
            {searchResult && searchResult.length == 0 && 
                <CategoryComp data={data} locale={locale} tables={tables} populateSearchResult={populateSearchResult} />
            }
            
            <div ref={searchRef}>
                {searchResult && searchResult.length > 0 && 
                    <ResultComp data={data} locale={locale} defaultLocale={defaultLocale} tables={tables} searchResult={searchResult}/>
                }
            </div>
        </>
    )
}

export default CategoryResult
