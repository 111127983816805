import React, { useEffect, useState, useRef } from "react"
import SearchBar from "@/components/sections/search-bar"
import CategoryResult from "@/components/sections/category-result"
import BlogList from "@/components/sections/blog-list"
import CastingCallList from "@/components/sections/casting-call-list"
import ContactUsForm from "@/components/sections/contact-us-form"
import ThreeColumnArticle from "@/components/sections/three-column-article"
import TwoColumnArticle from "@/components/sections/two-column-article"


// Map Strapi sections to section components
const sectionComponents = {
  "section.search-bar": SearchBar,
  "section.category-result": CategoryResult,
  "section.blog-list": BlogList,
  "section.casting-call-list": CastingCallList,
  "section.contact-us-form": ContactUsForm,
  "section.three-column-article": ThreeColumnArticle,
  "section.two-column-article": TwoColumnArticle,
}

// Display a section individually
const Section = ({ sectionData, locale, defaultLocale, tables, populateSearchResult, searchResult, searchRef }) => {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.strapi_component]

  if (!SectionComponent) {
    // No matching component for this page section
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} locale={locale} defaultLocale={defaultLocale} tables={tables} populateSearchResult={populateSearchResult} searchResult={searchResult} searchRef={searchRef} />
}

// Display the list of sections
const Sections = ({ sections, locale, defaultLocale, tables, populateSearchResult, searchResult, searchRef }) => {

  
  useEffect(() => {

  }, [])

  
  return (
    <>
      {sections.map((section, i) => (
        <Section
          sectionData={section}
          locale={locale}
          defaultLocale={defaultLocale}
          key={`${(section.id, i)}`}
          tables={tables}
          populateSearchResult={populateSearchResult}
          searchResult={searchResult}
          searchRef={searchRef}
        />
      ))}
    </>
  )
}

export default Sections
