import React from "react"
import ReactMarkdown from "react-markdown"
import { getStrapiMedia } from "../../utils/media"

const TwoColumnArticle = ({data, locale, defaultLocale }) => {

  return (
    <>
      <div className="about-us-entry">
        <section className="container">
          <h1 className="section-title text-center lines">{data.title}</h1>
          <div className="row post-content entry-text">
            <div className="col-md-6">
              <ReactMarkdown>{data.col1}</ReactMarkdown>
            </div>
            <div className="col-md-6">
              <ReactMarkdown>{data.col2}</ReactMarkdown>
            </div>
          </div>
        </section>
	    </div>
    </>
  )
}

export default TwoColumnArticle
